import React from "react";
import Link from "next/link";
import parse from "html-react-parser";
import BasicHero from "components/Hero/BasicHero";
import CustomParser from "modules/CustomParser";
import Paper from "@mui/material/Paper";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Balancer from "react-wrap-balancer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faStrava } from "@fortawesome/free-brands-svg-icons/faStrava";

import OneClickLogin from "components/Login/OneClickLogin.js";

import useTranslation from "next-translate/useTranslation";
import Box from "@mui/material/Box";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import ReviewsIcon from "@mui/icons-material/Reviews";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import BetaSignup from "pages-sections/dynamicPage/BetaSignup.js";
import SyncIcon from "@mui/icons-material/Sync";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";

import { makeStyles } from "tss-react/mui";
import { Button } from "@mui/material";

import WhatItIsSection from "../../components/Login/WhatItIsSection";
import { whiteColor } from "../../assets/jss/nextjs-material-kit-pro";
import { useRenders } from "../../modules/utils";
import { getExampleContent } from "../dashboard/settings/FeatureExample";
import { useScreenSizes } from "../../components/Providers/ScreenSizesProvider";
import CallToAction from "../../components/CallToAction/CallToAction";

const maxWidthCalc = "calc(50vw - 50px)";
const useStyles = makeStyles()((theme) => ({
  animateTextIn: {
    // Temporarily disabled while we work out how to prevent the header causing this to re-render
    /*   animation: keyframes`
      0% { transform: translate3d(0, -100%, 0); opacity: 0; }
      100% { transform: translate3d(0, 0, 0); opacity: 1;}`,
    animationDuration: '0.75s',
    opacity: 1,
    animationFillMode: 'forwards', */
  },
  timelineDot: { boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)" },
  connector: {
    backgroundImage: `linear-gradient( 136deg, ${theme.palette.secondary.dark}, ${theme.palette.secondary.light}, ${theme.palette.secondary.dark})`,
  },
  timelineContent: {
    padding: theme.spacing(1.5, 2),
    // py: '12px',
    // px: 2,
    width: "100%",
    alignSelf: "center",
    maxWidth: 545, //'536px',

    [theme.breakpoints.down("lg")]: {
      maxWidth: maxWidthCalc, // maxWidthCalc,
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "unset",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "calc(100vw - 72px)",
    },
  },
  syncGearPageCallToAction: {
    /*   maxWidth: 600,
    marginLeft: "auto",
    marginRight: "auto", */
    marginTop: theme.spacing(1),

    textAlign: "center",
    alignItems: "center",
    fontSize: "1.2rem",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1rem",
    },
  },
  buttonOnBackground: {
    "& a": {
      color: theme.palette.common.white,
    },

    "&:hover": {
      backgroundColor: "secondary.light",
      boxShadow: "none",
      // border: 'none',
    },
    border: "3px solid white",
  },
  loginButtonContainer: { justifyContent: "center", display: "flex" },
}));

const HeadlineAndSubtitlePre = ({ headline, subtitle }) => {
  const { classes } = useStyles();

  const headlineSx = {
    /*  transform:
          'translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)',
        transformStyle: 'preserve-3d',
        opacity: 1, */
  };

  headlineSx.fontSize = !subtitle
    ? {
        xxs: "2rem",
        xs: "2rem",
        sm: "2rem",
        md: "2.3rem",
        lg: "3rem",
      }
    : {
        xxs: "2.5rem",
        xs: "3rem",
        sm: "4rem",
        md: "5rem",
      };

  return (
    <>
      {headline && (
        <Box component="h1" sx={headlineSx} className={classes.animateTextIn}>
          <Balancer>{parse(headline)}</Balancer>
        </Box>
      )}
      {subtitle && (
        <Box
          component="h4"
          className={classes.animateTextIn}
          sx={{ maxWidth: 600, marginLeft: "auto", marginRight: "auto" }}
        >
          <Balancer ratio={1}>{parse(subtitle)}</Balancer>
        </Box>
      )}
    </>
  );
};
const HeadlineAndSubtitle = React.memo(HeadlineAndSubtitlePre);

export const CallToActionBlock = ({ callToAction, description }) => {
  const { classes } = useStyles();

  const { isXsScreen } = useScreenSizes();

  useRenders("callToActionBLock");
  switch (callToAction) {
    case "betaSignup":
      return <BetaSignup />;
    case "syncGearSignup":
    case "parkrunSignup":
      return (
        <Box className={classes.loginButtonContainer}>
          <OneClickLogin location={callToAction} />
        </Box>
      );

    case "syncGearPage":
      return (
        <Box sx={{ ml: "auto", mr: "auto", maxWidth: 600 }}>
          <GridContainer className={classes.syncGearPageCallToAction}>
            {description && (
              <>
                {!isXsScreen && (
                  <GridItem xs={1}>
                    <SyncIcon sx={{ fontSize: { sm: "3rem", md: "4rem" } }} />
                  </GridItem>
                )}
                <GridItem
                  xs={12}
                  sm={7}
                  sx={{ whiteSpace: "pre-line", mb: { xxs: 2, sm: 0 } }}
                >
                  <Balancer ratio={1}>
                    <CustomParser content={description} />
                  </Balancer>
                </GridItem>
              </>
            )}
            <GridItem xs={12} sm={description ? 4 : 12}>
              <Button
                color="secondary"
                size="medium"
                variant="onBackground"
                // className={classes.buttonOnBackground}
                endIcon={<FontAwesomeIcon icon={faStrava} />}
              >
                <Link href="/strava">Learn more</Link>
              </Button>
            </GridItem>
          </GridContainer>
        </Box>
      );
  }
};

const ZERO_PADDING_BLOCKS = ["shareProfileBlock"];

const getTimelineIcon = (block) => {
  switch (block) {
    case "priceComparisonBlock":
      return <RemoveShoppingCartIcon />;
    case "recommendationsBlock":
      return <ReviewsIcon />;
    case "parkrunStatsBlock":
      return <SyncAltIcon />;
    case "tagProgressBlock":
      return <WhatshotIcon />;
    case "statsBlock":
      return <TrendingUpIcon />;
    case "shareProfileBlock":
      return <AccountCircleIcon />;
  }
  return <AssignmentTurnedInIcon />;
};

const FeatureBlock = ({ block, classes, index, exampleAthlete, ...rest }) => {
  const { t } = useTranslation();
  const { isSmScreen } = useScreenSizes();
  //const isEven = index % 2;
  // const order = !isSmScreen && isEven ? 1 : 0; // 0 if index is even, 1 if it's odd
  //const rotateAngle = isSmScreen ? 0 : isEven ? -1 : 1;

  const zeroPadding = ZERO_PADDING_BLOCKS.includes(block);
  const content = (
    <>
      <h3>{t(`landingPages:features.${block}.title`)}</h3>
      <p>{t(`landingPages:features.${block}.content`)}</p>
    </>
  );
  const maxWidth = {
    xxs: 300,
    xs: "calc(100vw - 72px)",
    sm: 545,
    // md: 536,
    //   lg: maxWidthCalc,
  };
  return (
    <TimelineItem key={[block, index, "timeline"].join("-")}>
      {!isSmScreen && (
        <TimelineOppositeContent sx={{ m: "auto 0", maxWidth }}>
          {content}
        </TimelineOppositeContent>
      )}
      <TimelineSeparator>
        <TimelineConnector color="secondary" className={classes.connector} />
        <TimelineDot color="secondary" className={classes.timelineDot}>
          {getTimelineIcon(block)}
        </TimelineDot>
        <TimelineConnector className={classes.connector} />
      </TimelineSeparator>
      <TimelineContent
        // sx={{ py: '12px', px: 2, width: '100%', alignSelf: 'center' }}
        className={classes.timelineContent}
      >
        {isSmScreen && content}
        <Paper
          sx={{
            "& div > *": {
              textAlign: "left",
              pointerEvents: "none",
            },
            p: zeroPadding ? 0 : 2,
            maxWidth,
            "& img": { filter: "unset" },
            pointerEvents: "none",
            "& .MuiInputLabel-root": { color: "info.main" },
          }}
        >
          {getExampleContent(block, exampleAthlete, rest)}
        </Paper>
      </TimelineContent>
    </TimelineItem>
  );
};

const getLandingPageBlock = (props) => {
  const {
    blockObj,
    callToAction,
    fullWidth,
    backgroundColor,
    classes,
    index,
    ...rest
  } = props;

  const gridSizes = fullWidth
    ? {}
    : {
        xs: 12,
        md: 6,
      };
  const nonFeaturesSx = { sx: { pb: 4 } };
  const { block, faqs, heroText, description } = blockObj;

  if (!block) {
    throw new Error("No block supplied");
  }

  const key = [block, index].join("-");

  switch (block) {
    case "heroContent": {
      const { headline, subtitle } = heroText;

      return (
        <React.Fragment key={key}>
          <GridItem
            {...gridSizes}
            sx={{
              px: 2,
              textAlign: fullWidth ? "center" : "unset",
              maxWidth: 900,
            }}
          >
            <HeadlineAndSubtitle headline={headline} subtitle={subtitle} />
          </GridItem>
          <GridItem
            {...gridSizes}
            sx={{
              "& button": {
                "&:hover": {
                  backgroundColor: "secondary.light",
                  boxShadow: "none",
                  // border: 'none',
                },
                border:
                  backgroundColor && backgroundColor !== "none"
                    ? "3px solid white"
                    : "none",
              },
            }}
          >
            <CallToActionBlock callToAction={callToAction} />
          </GridItem>
        </React.Fragment>
      );
    }
    case "callToAction":
      return (
        <GridItem key={key} {...gridSizes} {...nonFeaturesSx}>
          <CallToActionBlock
            callToAction={callToAction}
            description={description}
          />
        </GridItem>
      );
    case "whatItIs":
      return <WhatItIsSection />;
    // case "gearManagementBlock":
    case "priceComparisonBlock":
    case "recommendationsBlock":
    case "statsBlock":
    case "parkrunStatsBlock":
    case "tagProgressBlock":
    case "shareProfileBlock":
      return (
        <FeatureBlock
          key={key}
          block={block}
          gridSizes={gridSizes}
          classes={classes}
          {...rest}
        />
      );
    case "faqsBlock":
      return (
        <GridItem key={key} {...nonFeaturesSx}>
          {faqs?.map((faq) => (
            <Accordion
              sx={{ py: 2, px: 1, mb: 2, borderRadius: "6px" }}
              key={faq.question}
            >
              <AccordionSummary
                sx={{ fontWeight: 500 }}
                expandIcon={<ExpandMoreIcon color="secondary" />}
              >
                <CustomParser content={`${faq.question}`} />
              </AccordionSummary>
              <AccordionDetails>
                <CustomParser content={faq.answer} />
              </AccordionDetails>
            </Accordion>
          ))}
        </GridItem>
      );
    /*  case "feedblock": {
      return (
        <FeedBlock
          sortImages
          noThumbnail
          feedItems={!pageTransition && categorySportEdge.products}
          feedType="product"
          feedTitle={t("feeds:trendingProducts", {
            sport: categorySportEdge.node.name.toLowerCase(),
            category: categoryName,
          })}
          fallbackImage={category.categoryDetails.fallbackImage}
          moreButtonHref={categorySportEdge.uri}
          moreButtonText={t("feeds:moreTrendingProducts", {
            sport: categorySportEdge.node.name.toLowerCase(),
            category: categoryName,
          })}
        />
      );
    } */
    case "default":
  }
};

const LandingPage = (props) => {
  const { pageData, exampleAthlete, shoeSports, shoeActivityTypes } = props;
  const {
    title,
    landingPageDetails: { pageSections, callToAction },
  } = pageData;

  const { classes } = useStyles();
  const { isSmScreen } = useScreenSizes();

  return (
    pageSections &&
    pageSections.map((section, index) => {
      const {
        backgroundColor,
        backgroundImage,
        fullWidth,
        fullHeight,
        timeline,
      } = section;
      const hasBackground = backgroundImage?.sourceUrl;

      const boxSx = {
        position: "relative",
        "& h2": { textAlign: "center" },
        "& img": { filter: "brightness(50%)" },
        p: 2,
        height: fullHeight ? "100vh" : "unset",
      };
      if (hasBackground) {
        //   boxSx.minHeight = 500;
        boxSx.display = "flex";
        boxSx.alignItems = "center";
        boxSx.backgroundColor =
          backgroundColor !== "none" ? backgroundColor : "primary.dark";
      } else {
        boxSx.backgroundColor = "white";
      }
      if (backgroundColor && backgroundColor !== "none") {
        boxSx.background = (theme) =>
          `linear-gradient(60deg, ${theme.palette.secondary.main}, ${theme.palette.secondary.dark})`;

        boxSx.color = "common.white";
        //  boxSx.minHeight = { xxs: '470px', xs: '400px', sm: '300px' };
      }

      const blocksContent = section.blocks?.map((blockObj, blockIndex) =>
        getLandingPageBlock({
          fullWidth:
            fullWidth ||
            (blockObj.block == "callToAction" && section.blocks.length === 1),
          blockObj: blockObj,
          callToAction,
          title,
          backgroundColor,
          index: blockIndex,
          exampleAthlete,
          shoeSports,
          shoeActivityTypes,
          classes,
        })
      );
      const isOddIndex = index % 2;
      return (
        <Box
          key={`landing-${index}`}
          sx={{ ...boxSx, minHeight: fullHeight ? 600 : 250 }}
        >
          {hasBackground && (
            <BasicHero
              image={section.backgroundImage.sourceUrl}
              alt={section.title || "Landing page hero image"}
              parralax={index === 0} // Only use at top of page
            />
          )}
          <Box
            sx={{
              maxWidth: 1200, // 1140,
              marginLeft: "auto",
              marginRight: "auto",
              my: 1,
            }}
          >
            {section.title && (
              <Box
                component="h2"
                sx={{
                  fontSize: { xxs: "1.8rem", xs: "2rem", sm: "2.5rem" },
                  fontWeight: 800,
                }}
              >
                {section.title}
              </Box>
            )}
            {timeline ? (
              <Timeline
                position={
                  isSmScreen
                    ? "right"
                    : !isOddIndex
                    ? "alternate-reverse"
                    : "alternate"
                }
                sx={
                  isSmScreen
                    ? {
                        p: 0,
                        pb: { xxs: 4, xs: "unset" },
                        [`& .${timelineItemClasses.root}:before`]: {
                          flex: 0,
                          padding: 0,
                        },
                        [`& .${timelineItemClasses.root}`]: {
                          padding: "0 !important",
                        },
                      }
                    : { alignItems: "center" }
                }
              >
                {blocksContent}
              </Timeline>
            ) : (
              <GridContainer
                /*  key={[blockObj.block, blockIndex, index].join('-')} */
                sx={{
                  zIndex: 1,
                  position: "relative",
                  // my: 1,
                  px: 2,
                  //  pb: 6,
                  //  minHeight: '300px',
                  color: hasBackground ? "common.white" : "unset",
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                }}
                columnSpacing={8}
                rowSpacing={4}
              >
                {blocksContent}
              </GridContainer>
            )}
          </Box>
        </Box>
      );
    })
  );
};

// Memoise to prevent animation refiring on rerender follow UserNav render
export default React.memo(LandingPage);
