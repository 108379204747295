import React from "react";
import PropTypes from "prop-types";

import Image from "components/Image/CustomImage";

export default function BasicHero({ image, alt, parralax }) {
  let windowScrollTop = 0;
  const [transform, setTransform] = React.useState(
    parralax ? "translate3d(0," + windowScrollTop + "px,0)" : null
  );
  React.useEffect(() => {
    if (parralax) {
      if (window.innerWidth >= 768) {
        window.addEventListener("scroll", resetTransform);
      }
      return function cleanup() {
        if (window.innerWidth >= 768) {
          window.removeEventListener("scroll", resetTransform);
        }
      };
    }
  });
  const resetTransform = () => {
    var windowScrollTop = window.pageYOffset / 3;
    setTransform("translate3d(0," + windowScrollTop + "px,0)");
  };

  return (
    <Image
      src={image}
      fill
      style={{ objectFit: "cover", objectPosition: "center", transform }}
      alt={alt}
    />
  );
}

BasicHero.propTypes = {
  alt: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};
