import React from "react";
import Box from "@mui/material/Box";
import WaitlistForm from "components/Forms/WaitlistForm";
import { useRenders } from "../../modules/utils";

export default function BetaSignup({ hideMessage }) {
  useRenders("betaSignup");
  return (
    <Box sx={{ maxWidth: 500, ml: "auto", mr: "auto", textAlign: "center" }}>
      {!hideMessage && (
        <>
          <h3>Join the beta</h3>
          <p>
            {"Sign up to the waitlist below to be one of the first to try it."}
          </p>
        </>
      )}
      <WaitlistForm addBackgroundToLabel />
    </Box>
  );
}
