import PropTypes from "prop-types";
import { useRouter } from "next/router";
import React from "react";

import { makeStyles } from "tss-react/mui";

import useTranslation from "next-translate/useTranslation";

// core components
import LoadingButton from "@mui/lab/LoadingButton";

import { getProviderLoginUrl } from "../../auth/utils";

const useStyles = makeStyles()((theme) => ({
  oneClickLoginButton: {
    "& .MuiLoadingButton-loadingIndicator": {
      color: theme.palette.common.white,
    },
    fontSize: "1.2rem",
  },
  loading: {
    backgroundColor: `${theme.palette.secondary.main} !important`,
    opacity: 0.8,
  },
}));

export default function OneClickLogin(props) {
  const { location } = props;

  const [loading, setLoading] = React.useState(false);

  const queryParams = {};
  if (location === "parkrunSignup") {
    queryParams.referrer = "parkrun";
  }
  const loginUrl = getProviderLoginUrl("strava", queryParams);

  const [loginModal, setLoginModal] = React.useState(false);

  const router = useRouter();

  React.useEffect(() => {
    if (router?.query?.login && !loginModal) {
      setLoginModal("login");
      router.push("/");
    }
  }, [loginModal, router]);

  const { classes } = useStyles();
  const { t } = useTranslation();

  const REDIRECT_ONLY = parseInt(process.env.NEXT_PUBLIC_REDIRECT_STRAVA_LOGIN);

  const redirect = React.useCallback(() => router.push("/beta"), [router]);

  return (
    <LoadingButton
      size="medium"
      href={loginUrl}
      variant="contained"
      loading={loading}
      disabled={false}
      classes={{
        root: classes.oneClickLoginButton,
        loading: classes.loading,
      }}
      onClick={() => (REDIRECT_ONLY ? redirect() : setLoading(true))}
    >
      <span>{t(`nav:loginTitle.getStarted`)}</span>
    </LoadingButton>
  );
}

OneClickLogin.propTypes = {
  location: PropTypes.string.isRequired,
};
