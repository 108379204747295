import React from "react";
import PropTypes from "prop-types";

import { Alert, AlertTitle } from "@mui/material";

import CustomFade from "components/Transition/CustomFade.js";

import Button from "components/CustomButtons/Button";

import CustomInput from "components/Inputs/CustomInput.js";

import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  submitButton: {
    height: 40,
  },
  contactFormBlock: {
    marginTop: "13px",
    // height: '100%',
    //  grid-template-columns: max-content 1fr;
    // grid-gap: 1rem;
    textAlign: "left",
    // padding-top: 2rem;
    // max-width: 25em,
    alignItems: "baseline",
    display: "grid",
  },
  multiEntryBlock: {
    "& button": {
      marginTop: theme.spacing(2),
      //  height: '40px',
    },
  },
  singleEntryBlock: {
    //  height: '40px',
    display: "flex",
    "& div": { flexGrow: 1 },
    "& button": {
      marginLeft: theme.spacing(1),
      //  height: '40px',
    },
    [theme.breakpoints.down("sm")]: {
      display: "grid",
      "& button": {
        marginLeft: "unset",
      },
    },
  },
}));

export const InputWithProps = ({
  name,
  state,
  handleInputChange,
  formValues,
  ...rest
}) => {
  const isError = state?.errors?.some((e) => !e.field || e.field === name);

  let errorMessage = isError
    ? `Error: ${state?.errors?.find((e) => e.field === name)?.message}`
    : null;

  if (!errorMessage && state.errors?.length) {
    errorMessage = JSON.stringify(state.errors);
  }
  if (errorMessage) {
    errorMessage = `Error: ${errorMessage}`;
  }
  return (
    <CustomInput
      id={name}
      name={name}
      {...rest}
      disabled={state?.submitting}
      value={formValues?.[name] || ""}
      onChange={handleInputChange}
      error={isError}
      helperText={errorMessage}
    />
  );
};
export default function FormComponent(props) {
  const {
    state,
    handleSubmit,
    children,
    alertTitle,
    alertMessage,
    submitTitle,
    singleEntry,
  } = props;
  const { classes, cx } = useStyles();

  const [formValues, setFormValues] = React.useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const logHandleSubmit = (event, formValues) => {
    event.preventDefault();
    //console.log('Handling submit', event?.nativeEvent);
    handleSubmit(event, formValues);
  };

  return (
    <>
      <CustomFade inProp={!state.succeeded}>
        <form
          onSubmit={(e) => logHandleSubmit(e, formValues)}
          className={cx({
            [classes.contactFormBlock]: true,
            [classes.multiEntryBlock]: !singleEntry,
            [classes.singleEntryBlock]: singleEntry,
          })}
        >
          {React.Children.map(children, (child) =>
            React.cloneElement(child, { state, formValues, handleInputChange })
          )}

          <Button
            type="submit"
            component="button"
            color="secondary"
            size="large"
            classes={{
              root: classes.submitButton + " " + classes.wrapText,
            }}
            disabled={state.submitting}
            showLoader
            loading={state.submitting}
          >
            {submitTitle}
          </Button>
        </form>
      </CustomFade>
      <CustomFade inProp={state.succeeded}>
        <div className={classes.contactFormBlock}>
          {(alertMessage || alertTitle) && (
            <Alert>
              {alertTitle && <AlertTitle>{alertTitle}</AlertTitle>}
              {alertMessage}
            </Alert>
          )}
        </div>
      </CustomFade>
    </>
  );
}

FormComponent.propTypes = {
  alertMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  alertTitle: PropTypes.string,
  children: PropTypes.any,
  handleSubmit: PropTypes.func,
  state: PropTypes.shape({
    submitting: PropTypes.bool,
    succeeded: PropTypes.bool,
  }),
  submitTitle: PropTypes.string,
};

FormComponent.defaultProps = {
  submitTitle: "Submit",
};
