import * as React from "react";
import Link from "next/link";
import { getExampleContent } from "pages-sections/dashboard/settings/FeatureExample";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStrava } from "@fortawesome/free-brands-svg-icons/faStrava";
import Balancer from "react-wrap-balancer";
import EmailMockup from "components/Mockups/EmailMockup.js";

import { makeStyles } from "tss-react/mui";
import {
  Box,
  Card,
  CardHeader,
  CardActions,
  Button,
  CardContent,
  Typography,
  Chip,
  Paper,
} from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  ctaInner: {
    width: "100%",
    borderRadius: theme.shape.borderRadius,
    backgroundImage: `linear-gradient(90deg, ${theme.palette.secondary.dark}, ${theme.palette.secondary.main})`,
  },
  newBadge: {
    borderRadius: theme.shape.borderRadius,
    fontWeight: 800,
    backgroundColor: theme.palette.common.white,
    color: theme.palette.secondary.main,
    marginRight: theme.spacing(1),
  },
}));

const getFallbackUser = () => ({
  athleteCharacteristics: { sex: { slug: "mens" } },
  athleteDetails: {
    fullName: "Tom Green",

    profileImages: {
      profile:
        "https://dgalywyr863hv.cloudfront.net/pictures/athletes/17153247/7273212/2/large.jpg",
    },
  },
  athleteProducts: {
    edges: [
      {
        product: {
          __typename: "Product",
          sports: {
            edges: [
              {
                isPrimary: false,
                node: {
                  id: "dGVybTo5Mw==",
                  slug: "running",
                  name: "Running",
                },
              },
            ],
          },
          id: "cG9zdDoyMDQwODc=",
          slug: "asics-gt-2000-10",
          databaseId: 204087,
          uri: "/model/asics-gt-2000-10",
          title: "Asics GT 2000 10",
        },
        items: [
          {
            __typename: "Item",
            id: "cG9zdDoyMzUwMTQ=",
            databaseId: 235014,
            date: "2023-01-18T02:28:17",
            itemStats: {
              __typename: "Item_Itemstats",
              distance: 489024,
              averages: {
                __typename: "Item_Itemstats_Averages",
                speed: 3.667,
                cadence: 85.726,
              },
            },
            itemDetails: {
              __typename: "Item_Itemdetails",
              description: "Autum  2022",
              stravaDefault: true,
              integrationId: "g12029421",
              modelName: "ASICS GT 2000 10 Autum  2022",
              productSelectedManually: null,
              new: null,
              retired: null,
            },
          },
        ],
        settings: {
          __typename: "SettingsObject",
          distanceAlertThreshold: 400,
          distanceAlerts: true,
          priceAlertThreshold: null,
          priceAlerts: true,
          newModelAlerts: null,
          newReviewAlerts: null,
          shoeSize: null,
        },
        usageStatus: "using",
        reviews: null,
      },
    ],
  },
});

export default function CallToAction({ callToAction }) {
  const { classes } = useStyles();
  const getCtaContent = () => {
    let description, title, sideContent, href;
    const user = getFallbackUser();
    switch (callToAction) {
      case "parkrun":
        description = (
          <>
            <Chip
              label="new!"
              size="small"
              classes={{ root: classes.newBadge }}
            />
            Sync your <strong>parkrun</strong> results to your{" "}
            <strong>Strava</strong> activities
          </>
        );
        sideContent = getExampleContent("parkrunStatsSync", user);
        href = "/parkrun";
        break;

      case "syncGear": {
        title = "Save time and money by syncing with Strava";
        description = (
          <>
            Track your gear with <strong>Strava</strong>? Sync with geerly for{" "}
            <strong>automated price alerts</strong> and{" "}
            <strong>personalised recommendations</strong>
          </>
        );
        sideContent = (
          <Box sx={{ p: 2 }}>
            <EmailMockup user={user} />
          </Box>
        );
        href = "/strava";
      }
    }
    return { description, title, sideContent, href };
  };

  const { description, title, href, sideContent } = getCtaContent();

  return (
    <Card
      sx={{ display: "flex", padding: 1 }}
      elevation={12}
      variant="elevation"
    >
      <Box className={classes.ctaInner}>
        {title && (
          <CardHeader
            title={
              <Box
                component="h1"
                sx={{ color: "common.white", textAlign: "center" }}
              >
                <Balancer>{title}</Balancer>
              </Box>
            }
          />
        )}
        <GridContainer>
          <GridItem
            sm={sideContent ? 6 : 12}
            lg={sideContent ? 7 : 12}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <CardContent>
              {description && (
                <Typography
                  component="div"
                  variant="h5"
                  sx={{ color: "common.white", textAlign: "center" }}
                >
                  <Balancer>{description}</Balancer>
                </Typography>
              )}
            </CardContent>
            <CardActions>
              <Button
                size="small"
                color="primary"
                variant="onBackground"
                component={Link}
                href={href || "#"}
                endIcon={<FontAwesomeIcon icon={faStrava} size="xs" />}
                sx={{ fontSize: "1.1rem", my: 2 }}
              >
                Find out more
              </Button>
            </CardActions>
          </GridItem>
          {sideContent && (
            <GridItem
              sm={6}
              lg={5}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Paper sx={{ m: 2, ml: 3 }}>{sideContent}</Paper>
            </GridItem>
          )}
        </GridContainer>
      </Box>
    </Card>
  );
}

/* 
export default function CallToAction(props) {
  const { callToAction } = props;
  return (
    <Card sx={{ width: "100%", height: "100px", backgroundColor: "" }}>
      New! Sync your parkrun results to your Strava activities
    </Card>
  );
}
 */
