import React from "react";
import { useRouter } from "next/router";
import cookie from "react-cookies";

import FormComponent, {
  InputWithProps,
} from "components/Forms/FormComponent.js";
import { scrollToTop } from "../../modules/utils";

export default function WaitlistForm(props) {
  const { setHasBetaAccess, addBackgroundToLabel, login } = props;
  const [state, setState] = React.useState({ value: null });
  const router = useRouter();

  const handleSubmit = async (event, formValues) => {
    setState({ ...state, submitting: true });

    const { email } = formValues;
    if (!email) {
      throw new Error("no email ");
    }

    const API_KEY = process.env.NEXT_PUBLIC_WAITLIST_API_KEY;

    if (!API_KEY) {
      throw new Error("No API found in this ENV");
    }

    const body = {
      email,
      api_key: API_KEY,
      referral_link: event.target.BaseURI,
    };
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
    };

    const res = await fetch(
      "https://api.getwaitlist.com/api/v1/waiter",
      options
    )
      .then((response) => {
        if (!response.ok) {
          console.error(
            "Got bad response:",
            response.statusText || response.status
          );
          if (response.errors) {
            setState({
              ...state,
              errors: response.errors,
            });
          }
        } else {
          return response.json();
        }
      })
      .catch((err) => {
        console.error(err);
        setState({ ...state, errors: [err] });
      });
    if (!res) {
      console.error("WaitlistAPI failed to send a response");
      if (!state.errors) {
        setState({
          ...state,
          errors: ["Login timed out. Please try again later"],
        });
      }
    } else {
      // NB: Used to be removed_from_waitlist but they seem to have changed the API
      const { priority, current_priority, removed_date } = res;

      let successTitle;
      let successMessage = "";
      if (removed_date) {
        if (setHasBetaAccess) {
          setHasBetaAccess("hasAccess");
          cookie.save("hasBetaAccess", "hasAccess", { path: "/" });
          if (router.asPath !== "/") {
            scrollToTop();
            router.push("/");
          }
        }
        successTitle = "You've already got access!";
        successMessage = (
          <span>
            Head to{" "}
            <a href="https://beta.geerly.com" target="_blank" rel="noreferrer">
              the beta site
            </a>{" "}
            to log in
          </span>
        );
      } else {
        const priorityToUse = priority || current_priority;
        if (priorityToUse) {
          successMessage = `You're currently number ${
            priority || current_priority
          } in the queue. `;
        }
        successMessage += `We'll let you know when you're at the front, and how you can move yourself up the queue and invite others to join`;
        if (setHasBetaAccess) {
          setHasBetaAccess("hasApplied");
        }
        if (login) {
          successTitle =
            "You do not currently have access to the beta. You've been added to the waitlist";
        } else {
          successTitle = "Thanks for joining the waitlist";
        }
      }
      setState({
        ...state,
        succeeded: true,
        submitting: false,
        successMessage,
        successTitle,
      });
    }
  };

  return (
    <FormComponent
      state={state}
      handleSubmit={handleSubmit}
      alertTitle={state.successTitle}
      alertMessage={state.successMessage}
      submitTitle={login ? "Take me to the beta" : "Join the waitlist!"}
      singleEntry={!login}
    >
      <InputWithProps
        type="email"
        name="email"
        label="Email address"
        placeholder="email@domain.com"
        required
        addBackgroundToLabel={addBackgroundToLabel}
      />
    </FormComponent>
  );
}
